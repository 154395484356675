<template>
	<el-aside class="left_tree" :width="treeWidth + 'px'">
		<div class="top_box">
			<div class="item_mode" @click="viewVeh(0)">
				<img :src="topBoxIcon(0)" alt />
				<div class="font_s" :class="topBoxFont(0)">全部</div>
				<div class="font_n" :class="topBoxFont(0)">({{ total }})</div>
			</div>
			<div class="item_mode" @click="viewVeh(1)">
				<img :src="topBoxIcon(1)" alt />
				<div class="font_s" :class="topBoxFont(1)">在线</div>
				<div class="font_n" :class="topBoxFont(1)">({{ onTotal }})</div>
			</div>
			<div class="item_mode" @click="viewVeh(-1)">
				<img :src="topBoxIcon(-1)" alt />
				<div class="font_s" :class="topBoxFont(-1)">离线</div>
				<div class="font_n" :class="topBoxFont(-1)">({{ offTotal }})</div>
			</div>
		</div>
		<el-tabs type="card" class="tabs">
			<el-tab-pane label="全部">
				<all-tree @change="changeSelect" @click="handleNodeClick" :showDevice="showDevice"></all-tree>
			</el-tab-pane>
			<el-tab-pane label="组织">
				<org-tree @change="changeSelect" @click="handleNodeClick"></org-tree>
			</el-tab-pane>
			<el-tab-pane label="线路">
				<route-tree @change="changeSelect" @click="handleNodeClick"></route-tree>
			</el-tab-pane>
			<el-tab-pane label="场站">
				<depot-tree @change="changeSelect" @click="handleNodeClick"></depot-tree>
			</el-tab-pane>
			<el-tab-pane label="司机">
				<driver-tree @change="changeSelect" @click="handleNodeClick"></driver-tree>
			</el-tab-pane>
		</el-tabs>
	</el-aside>
</template>
<script>
import allTree from "./trees/allTree.vue";
import orgTree from "./trees/orgTree.vue";
import routeTree from "./trees/routeTree.vue";
import depotTree from "./trees/depotTree.vue";
import driverTree from "./trees/driverTree.vue";
/**
 *  0-机构 1-车辆 2-设备 3-通道
 *  机构：0-机构 1-车队 2-线路
 * type: 0:组织 1：车辆 2：设备 4：场站 5：司机
 */
export default {
	props: {
		showDevice: {
			type: Boolean
		}
	},
	data() {
		return {
			activeBtn: 0,
			total: 0,
			onTotal: 0,

			loading: false,
			treeProps: {
				label: "text",
				children: "children",
				isLeaf: "leaf"
			},

			treeWidth: 300,
			anime: 0,
			maxWidth: 300,
			count: 0
		};
	},
	components: {
		allTree,
		orgTree,
		routeTree,
		depotTree,
		driverTree
	},
	computed: {
		offTotal() {
			return this.total - this.onTotal;
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		showCheckBox(data) {
			return data.type === 1 || data.isline;
		},
		init() {
			this.getAllCount().then(number => {
				this.total = number;
			});
			this.getOnlineCount().then(number => {
				this.onTotal = number;
			});
		},
		getAllCount(orgId) {
			return this.$http.post("/base/vehicle/queryVehicleCount", { orgId }, { type: "format" }).then(res => {
				return res.detail;
			});
		},
		getOnlineCount(orgId) {
			return this.$http.post("/realtime/getVehicleOnlineCount", { orgId }, { type: "format" }).then(res => {
				return res.detail;
			});
		},
		changeSelect(data, e) {
			this.$emit("change", data, e.target.checked);
		},
		setWidth(width) {
			if (width > 300 && width < 500) {
				this.treeWidth = width;
				this.maxWidth = width;
			}
		},
		getWidth() {
			return this.treeWidth;
		},
		open() {
			// 打开左边菜单栏
			if (this.treeWidth >= this.maxWidth) {
				this.treeWidth = this.maxWidth;
				window.cancelAnimationFrame(this.anime);
				this.count = 0;
			} else {
				this.count += 1;
				this.treeWidth += this.easeInQuad(this.count);
				this.anime = window.requestAnimationFrame(this.open);
			}
		},
		close() {
			// 关闭左边菜单栏
			if (this.treeWidth <= 0) {
				this.treeWidth = 0;
				window.cancelAnimationFrame(this.anime);
				this.count = 0;
			} else {
				this.count += 1;
				this.treeWidth -= this.easeInQuad(this.count);
				this.anime = window.requestAnimationFrame(this.close);
			}
		},
		easeInQuad: x => {
			return x * x;
		},
		setChecked(id, checked) {
			if (id) {
				const checkbox = document.getElementById(id);
				checkbox.checked = checked;
			}
		},
		handleNodeClick(data) {
			this.$emit("click", data);
		},
		viewVeh() {},
		topBoxFont(type) {
			return this.activeBtn === type ? "font_active" : "font_normal";
		},
		topBoxIcon(type) {
			let typeStr = "all";
			let alive = "checked";
			alive = this.activeBtn == type ? "checked" : "normal";
			switch (type) {
				case 0:
					typeStr = "all";
					break;
				case 1:
					typeStr = "online";
					break;
				case -1:
					typeStr = "offline";
					break;
			}
			let url = require("@/assets/serviceMap/monitor_" + typeStr + "_" + alive + ".svg");
			return url;
		},
		getOrgNames(id) {
			let node = this.$refs.tree.getNode(id),
				parent = node.data,
				orgNames = [];
			while (parent) {
				if (parent.type === 0) {
					orgNames.push(parent.text);
				}
				parent = parent.pData;
			}
			orgNames.reverse();
			return orgNames.join("");
		}
	}
};
</script>
<style lang="scss" scoped="left_tree">
.checkbox {
	cursor: pointer;
}
.left_tree {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	height: 100%;

	.top_box {
		display: flex;
		justify-content: space-around;
		background-color: white;
		padding: 5px;
		height: 85px;
		flex-shrink: 0;

		.item_mode {
			text-align: center;
			margin: 5px 0;
			cursor: pointer;

			img {
				width: 28px;
			}

			.font_s,
			.font_n {
				text-align: center;
				margin: 2px 0;
				font-weight: 600;

				&.font_normal {
					color: #333333;
				}

				&.font_active {
					color: #4178bd;
				}
			}

			.font_s {
				font-size: 12px;
			}

			.font_n {
				font-size: 15px;
			}
		}
	}

	.tabs {
		height: 100%;
		overflow: auto;
	}

	.vehicle_tree {
		flex: 1;
		overflow-y: auto;
		// max-height: 100%;
		margin-top: 10px;
		overflow-x: hidden;
	}

	.tree_node_span {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
		font-weight: 600;

		.content_span {
			vertical-align: middle;
			color: #333333;

			&.online {
				color: #36ab60;
			}

			img {
				vertical-align: middle;
				margin-right: 2px;
				width: 16px;
			}
		}
	}
}
</style>
