<template>
	<div class="car-monitor-container">
		<tree-com :showDevice="isAdmin" @change="changeSelect" @click="nodeClick" ref="treeCompref"></tree-com>
		<detail-panel ref="detailPanel"></detail-panel>
		<div class="map-container" ref="container"></div>
	</div>
</template>
<script>
import TreeCom from "./components/treeCom.vue";
import DetailPanel from "./components/detailPanel.vue";

import gcoord from "gcoord";
import * as maptalks from "maptalks";
import { ClusterLayer } from "maptalks.markercluster";

import PreTrackDB from "./utils/PreTrackDB";

import busOn from "@/assets/serviceMap/car_info.png";
import busOff from "@/assets/serviceMap/car_off.png";
import m0 from "@/assets/serviceMap/m0.png";
import m1 from "@/assets/serviceMap/m1.png";
import m2 from "@/assets/serviceMap/m2.png";
import m3 from "@/assets/serviceMap/m3.png";
import m4 from "@/assets/serviceMap/m4.png";

const preTrackDB = new PreTrackDB();

let serviceMap = null,
	clusterLayer = null,
	trackLayer = null;
const clusterOptions = {
	noClusterWithOneMarker: false,
	maxClusterZoom: 16,
	symbol: {
		markerVerticalAlignment: "middle",
		markerHorizontalAlignment: "middle",
		markerFile: {
			property: "count",
			type: "interval",
			stops: [
				[0, m0],
				[9, m1],
				[99, m2],
				[200, m3],
				[500, m4]
			]
		},
		markerWidth: {
			property: "count",
			type: "interval",
			stops: [
				[0, 53],
				[9, 56],
				[99, 66],
				[200, 79],
				[500, 90]
			]
		},
		markerHeight: {
			property: "count",
			type: "interval",
			stops: [
				[0, 53],
				[9, 56],
				[99, 66],
				[200, 79],
				[500, 90]
			]
		}
	},
	drawClusterText: true,
	geometryEvents: true,
	single: true,
	zIndex: 9
};

export default {
	name: "carMonitor",
	components: { TreeCom, DetailPanel },
	data() {
		return {};
	},
	computed: {
		isAdmin() {
			return !!this.$store.state.info.isAdmin;
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.initMap();
			// 预设线路
			this.initVectorLayer();
			// 聚合
			this.initCluster();
		});
	},
	methods: {
		initVectorLayer() {
			trackLayer = new maptalks.VectorLayer("vector").addTo(serviceMap);
		},
		async initCluster() {
			let restRecord = 0,
				vlist = [],
				pageIndex = 1;
			do {
				const result = await this.$http.post("/realtime/getRTVehicleInfo", {
					pageIndex,
					pageSize: 200
				});
				const { total, list } = result.detail;
				vlist = vlist.concat(list);
				restRecord = total - vlist.length;
				pageIndex++;
			} while (restRecord > 0);
			const markers = this.transformPoi2Marker(vlist);
			clusterLayer = new ClusterLayer("cluster", markers, clusterOptions);
			serviceMap && serviceMap.addLayer(clusterLayer);

			serviceMap.on("click", function (e) {
				const clusterList = clusterLayer.identify(e.coordinate).children;
				if (clusterList?.length) {
					serviceMap.setCenter(clusterList[0].getCoordinates());
					serviceMap.setZoom(19);
				}
			});

			const fm = markers[0];
			fm && serviceMap.setCenter(fm.getCoordinates());
		},
		transformPoi2Marker(list) {
			let markers = [];
			if (list && list.length) {
				list.forEach(v => {
					const { vhNo: plateNum, vhId: vehicleId, devicePosList, online } = v;
					const { lng, lat } = devicePosList[0];
					if (lng && lat) {
						const point = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
						const marker = new maptalks.Marker(point, {
							id: vehicleId,
							properties: v,
							cursor: "pointer",
							symbol: [
								{
									markerFile: online === 1 ? busOn : busOff, //标注点图标
									markerWidth: 62,
									markerHeight: 82
								},
								{
									textName: plateNum,
									textSize: 14,
									textHaloRadius: 10,
									textDy: -95
								}
							]
						}).on("click", this.clickMarker);
						markers.push(marker);
					}
				});
			}
			return markers;
		},
		changeLine(data, checked) {
			if (checked) {
				preTrackDB.read(data.id).then(detail => {
					const coordinates = detail.map(p => {
						return gcoord.transform([p.lng, p.lat], gcoord.WGS84, gcoord.BD09);
					});
					trackOptions.id = data.id;
					trackOptions.properties = data;
					const track = new maptalks.LineString(coordinates, trackOptions).on("click", this.lineClick);
					track.addTo(trackLayer);
				});
			} else {
				// 移除
				const track = trackLayer.getGeometryById(data.id);
				track.remove();
			}
		},
		clickMarker(event) {
			const properties = event.target.properties;
			this.$refs.detailPanel.openPanel(properties);
			console.log(properties, "地图车辆点击事件");
		},
		nodeClick(data) {
			if (data.type === 2) {
				this.$refs.device_info.init({
					deviceId: data.id,
					plateNum: data.pData.text,
					orgName: this.getOrgName(data)
				});
			} else if (data.type === 1) {
				console.log(data, "-----");
				const marker = clusterLayer.getGeometryById(data.id);
				serviceMap.setCenter(marker.getCoordinates());
				serviceMap.setZoom(19);
			} else if (data.type === 4) {
				this.$refs.depot_info.init({
					depotId: data.id
				});
			}
		},
		changeVehicle(data, checked) {
			console.log(data, checked, "树选中车辆");
		},
		changeSelect(data, checked) {
			if (data.type == 1) {
				this.changeVehicle(data, checked);
			} else if (data.type == 0) {
				this.changeLine(data, checked);
				// this.chengeSite(data, checked);
				if (data.isline == true) {
					// this.$refs.routeInfo.init(data, checked);
					// this.$refs.route.init(data, checked);
				} else {
					// this.$refs.org.init(data, checked);
				}
			} else if (data.type === 5) {
				// this.$refs.driver.init(data, checked);
			}
		},

		initMap() {
			const dom = this.$refs.container;
			serviceMap = new maptalks.Map(dom, {
				center: [89.187441, 42.957145],
				zoom: 13,
				minZoom: 6,
				// maxZoom: 16,
				spatialReference: {
					projection: "baidu"
				},
				attribution: false,
				baseLayer: new maptalks.TileLayer("map", {
					urlTemplate: `//maponline{s}.bdimg.com/tile/?qt=vtile&x={x}&y={y}&z={z}&styles=pl&scaler=1&udt=${this.getCurrentDate}`,
					subdomains: [0, 1, 2, 3]
				})
			});
			// 限制地图区域
			const extent = new maptalks.Extent(70, 0, 130, 55);
			serviceMap.setMaxExtent(extent);
		}
	}
};
</script>
<style lang="scss" scoped>
.car-monitor-container {
	display: flex;
	height: 100%;
	position: relative;
	overflow: hidden;
	.map-container {
		flex: 1;
	}
}
</style>
