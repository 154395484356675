<template>
	<el-drawer :visible.sync="visible" :title="`${carBase.plateNo || ''} | ${carBase.vin || ''}`" size="70%">
		<div class="card">
			<el-descriptions title="整车数据" direction="horizontal" :column="4" border>
				<el-descriptions-item label="车辆状态">{{ carStatus[carInfo.ovInfo?.vs] || "--" }}</el-descriptions-item>
				<el-descriptions-item label="充电状态">{{ chargeStatus[carInfo.ovInfo?.cs] || "--" }}</el-descriptions-item>
				<el-descriptions-item label="运行模式">{{ runModel[carInfo.ovInfo?.rm] || "--" }}</el-descriptions-item>
				<el-descriptions-item label="车速(km/h)">{{ carInfo.ovInfo?.cspd }}</el-descriptions-item>
				<el-descriptions-item label="累计里程(km)">{{ carInfo.ovInfo?.mil }}</el-descriptions-item>
				<el-descriptions-item label="总电压(V)">{{ carInfo.ovInfo?.tvol }}</el-descriptions-item>
				<el-descriptions-item label="总电流(A)">{{ carInfo.ovInfo?.tcur }}</el-descriptions-item>
				<el-descriptions-item label="soc(%)">{{ carInfo.ovInfo?.soc }}</el-descriptions-item>
				<el-descriptions-item label="DC-DC状态">{{ dcdcStatus[carInfo.ovInfo?.dcs] || "--" }}</el-descriptions-item>
				<el-descriptions-item label="档位">{{
					carInfo.ovInfo?.gears === 0
						? "空挡"
						: carInfo.ovInfo?.gears === 15
							? "P档"
							: carInfo.ovInfo?.gears === 14
								? "D档"
								: `${carInfo.ovInfo?.gears}档`
				}}</el-descriptions-item>
				<el-descriptions-item label="绝缘电阻(kΩ)">{{ carInfo.ovInfo?.resist }}</el-descriptions-item>
				<el-descriptions-item label="加速踏板行程值(%)">{{ carInfo.ovInfo?.accelv }}</el-descriptions-item>
				<el-descriptions-item label="制动踏板状态(%)">{{ carInfo.ovInfo?.breaks }}</el-descriptions-item>
			</el-descriptions>
		</div>
		<div class="card">
			<el-descriptions title="车辆位置数据" direction="horizontal" :column="4" border>
				<el-descriptions-item label="定位状态">{{ posStatus[carInfo.pos?.valid] || "--" }}</el-descriptions-item>
				<el-descriptions-item :label="`经度(东经)`">{{ carInfo.pos?.lng }}</el-descriptions-item>
				<el-descriptions-item :label="`纬度(北纬)`">{{ carInfo.pos?.lat }}</el-descriptions-item>
			</el-descriptions>
		</div>
		<div class="card">
			<el-descriptions title="驱动电机数据" direction="horizontal" :column="4" border>
				<el-descriptions-item label="驱动电机个数">{{ 1 }}</el-descriptions-item>
				<el-descriptions-item label="电机序号">{{ carInfo.emotor?.index }}</el-descriptions-item>
				<el-descriptions-item label="驱动电机状态">{{ eMotoStatus[carInfo.emotor?.status] || "--" }}</el-descriptions-item>
				<el-descriptions-item label="驱动电机控制器温度(℃)">{{ carInfo.emotor?.ctemp }}</el-descriptions-item>
				<el-descriptions-item label="驱动电机转速(r/min)">{{ carInfo.emotor?.scrspd }}</el-descriptions-item>
				<el-descriptions-item label="驱动电机转扭(N·m)">{{ carInfo.emotor?.torque }}</el-descriptions-item>
				<el-descriptions-item label="驱动电机温度(℃)">{{ carInfo.emotor?.temp }}</el-descriptions-item>
				<el-descriptions-item label="电机控制器输入电压(V)">{{ carInfo.emotor?.civol }}</el-descriptions-item>
				<el-descriptions-item label="电机控制器直流母线电流(A)">{{ carInfo.emotor?.ccur }}</el-descriptions-item>
			</el-descriptions>
		</div>
		<div class="card">
			<el-descriptions title="极值数据" direction="horizontal" :column="4" border>
				<el-descriptions-item label="最高电压电池子系统号">{{ carInfo.limit?.mvolc }}</el-descriptions-item>
				<el-descriptions-item label="最高电压电池单体代号">{{ carInfo.limit?.mvolsc }}</el-descriptions-item>
				<el-descriptions-item label="电池单体电压最高值">{{ carInfo.limit?.svolmv }}</el-descriptions-item>
				<el-descriptions-item label="最低电压电池子系统号">{{ carInfo.limit?.minvolc }}</el-descriptions-item>
				<el-descriptions-item label="最低电压电池单体代号">{{ carInfo.limit?.minvolsc }}</el-descriptions-item>
				<el-descriptions-item label="电池单体电压最低值">{{ carInfo.limit?.svolminv }}</el-descriptions-item>
				<el-descriptions-item label="最高温度子系统号">{{ carInfo.limit?.mtempc }}</el-descriptions-item>
				<el-descriptions-item label="最高温度探针序号">{{ carInfo.limit?.mtemppc }}</el-descriptions-item>
			</el-descriptions>
		</div>
	</el-drawer>
</template>
<script>
import mixinsData from "@/common/js/carMonitor.mixin.js";
export default {
	name: "detailPanel",
	mixins: [mixinsData],
	data() {
		return {
			visible: false,
			carInfo: {
				title: "车牌|车辆ID"
			},
			carBase: {}
		};
	},
	methods: {
		openPanel(carInfo) {
			this.visible = true;
			this.getRealTime(carInfo);
		},
		getRealTime(data) {
			// const { devicePosList } = data;
			// const carInfo = devicePosList[0];
			// const { clientId } = carInfo;
			const { vhId } = data;
			const url = "/crrc-palm-bus/vehicleMonitor/queryGb32960History";
			const carInfoUrl = "/crrc-palm-bus/vehicleMonitor/realTimeInfo";
			this.$http.post(carInfoUrl, { vehicleId: vhId }, { baseType: "base" }).then(res => {
				this.carBase = res;
			});
			this.$http.post(url, { vehicleId: vhId }, { baseType: "base" }).then(res => {
				this.carInfo = res;
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.card {
	padding: 20px;
}
</style>
