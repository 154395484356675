<template>
	<el-tree
		class="vehicle_tree"
		ref="tree"
		v-loading="loading"
		node-key="id"
		lazy
		:load="loadNode"
		:props="treeProps"
		@node-click="handleNodeClick"
	>
		<span slot-scope="{ node, data }" class="tree_node_span">
			<span class="content_span" :style="{ color: data.online == 1 ? '#2ecc71' : 'black' }">
				<input
					class="checkbox"
					:id="node.data.id"
					type="checkbox"
					v-if="showCheckBox(data)"
					@change.stop="changeSelect(data, $event)"
				/>
				{{ data.text }}
				<span class="number" v-if="data.type === 0">（{{ data.onTotal }}/{{ data.total }}）</span>
			</span>
		</span>
	</el-tree>
</template>
<script>
/**
 *  0-机构 1-车辆 2-设备 3-通道
 *  机构：0-机构 1-车队 2-线路
 */
export default {
	props: {
		showDevice: {
			type: Boolean
		}
	},
	data() {
		return {
			activeBtn: 0,
			total: 0,
			onTotal: 0,

			loading: false,
			treeProps: {
				label: "text",
				children: "children",
				isLeaf: "leaf"
			},

			treeWidth: 300,
			anime: 0,
			maxWidth: 300,
			count: 0
		};
	},
	computed: {
		offTotal() {
			return this.total - this.onTotal;
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		showCheckBox(data) {
			return data.type === 1 || data.type === 4 || data.type === 5 || data.isline;
		},
		init() {
			this.getAllCount().then(number => {
				this.total = number;
			});
			this.getOnlineCount().then(number => {
				this.onTotal = number;
			});
		},
		getAllCount(orgId) {
			return this.$http
				.post(
					"/base/vehicle/queryVehicleCount",
					{
						orgId
					},
					{
						type: "format"
					}
				)
				.then(res => {
					return res.detail;
				});
		},
		getOnlineCount(orgId) {
			return this.$http
				.post(
					"/realtime/getVehicleOnlineCount",
					{
						orgId
					},
					{
						type: "format"
					}
				)
				.then(res => {
					return res.detail;
				});
		},
		changeSelect(data, e) {
			this.$emit("change", data, e);
		},
		setWidth(width) {
			if (width > 300 && width < 500) {
				this.treeWidth = width;
				this.maxWidth = width;
			}
		},
		getWidth() {
			return this.treeWidth;
		},
		open() {
			// 打开左边菜单栏
			if (this.treeWidth >= this.maxWidth) {
				this.treeWidth = this.maxWidth;
				window.cancelAnimationFrame(this.anime);
				this.count = 0;
			} else {
				// this.treeWidth ++;
				this.count += 1;
				this.treeWidth += this.easeInQuad(this.count);
				this.anime = window.requestAnimationFrame(this.open);
			}
		},
		close() {
			// 关闭左边菜单栏
			if (this.treeWidth <= 0) {
				this.treeWidth = 0;
				window.cancelAnimationFrame(this.anime);
				this.count = 0;
			} else {
				this.count += 1;
				this.treeWidth -= this.easeInQuad(this.count);
				this.anime = window.requestAnimationFrame(this.close);
			}
		},
		easeInQuad: x => {
			return x * x;
		},
		setChecked(id, checked) {
			if (id) {
				const checkbox = document.getElementById(id);
				checkbox.checked = checked;
			}
		},
		handleNodeClick(data) {
			this.$emit("click", data);
		},
		loadNode(node, resolve) {
			// let id = "",
			//     type = 0;
			let option = {
				id: "",
				type: 0,
				vehicle: 1,
				device: 1,
				station: 1,
				route: 1,
				driver: 1
			};
			if (node.data && node.data.id) {
				option.id = node.data.id;
				option.type = node.data.type;
				(option.vehicle = 1), (option.device = 1), (option.station = 1), (option.route = 1), (option.driver = 1);
			}
			this.getTree(option, node, resolve);
		},
		getTree(option, node, resolve) {
			let deviceIds = [];
			let vehicleIds = [];
			this.$http.post("/base/resource/getTree", option).then(async ({ detail }) => {
				let children = [];
				if (detail.length > 0) {
					let originList = detail[0].children;
					for (let index = 0; index < originList.length; index++) {
						let text, id, type, total, onTotal, isline, porgId, customNo, vin;
						const v = originList[index];
						if (v.type === 0) {
							text = v.data.orgNa;
							id = v.data.orgId;
							type = 0;
							isline = v.data.type == 2;
							porgId = v.data.porgId;
							total = await this.getAllCount(id);
							onTotal = await this.getOnlineCount(id);
						} else if (v.type === 1) {
							text = v.data.plateNum;
							id = v.data.vehicleId;
							type = 1;
							customNo = v.data.customNo;
							vin = v.data.vin;
							vehicleIds.push(id);
						} else if (v.type === 2) {
							text = v.data.deviceInfo.deviceId;
							id = v.data.deviceInfo.deviceId;
							type = 2;
							deviceIds.push(id);
						} else if (v.type === 4) {
							text = v.data.cname;
							id = v.data.stationId;
							type = 4;
						} else if (v.type === 5) {
							text = v.data.cname;
							id = v.data.driverId;
							type = 5;
						}

						children.push({
							text,
							id,
							type,
							pData: node.data,
							leaf: type === 2 || type === 4 || type === 5 || (type === 1 && !this.showDevice),
							total,
							onTotal,
							isline,
							porgId,
							vin,
							customNo
						});
					}
					if (deviceIds.length) {
						this.$http.post("/base/device/getDeviceOnLineStatus", deviceIds).then(res => {
							res.detail.forEach(o => {
								const child = children.find(c => c.id === o.clientId);
								if (child) {
									child.online = o.online;
								}
							});
							resolve(children);
						});
					} else if (vehicleIds.length) {
						this.$http.post("/realtime/getVehicleOnlineStatus", vehicleIds).then(res => {
							res.detail.forEach(v => {
								const one = children.find(c => c.id === v.vhId);
								one.online = v.online;
							});
							resolve(children);
						});
					} else {
						resolve(children);
					}
				} else {
					resolve(children);
				}
			});
		},
		getOrgNames(id) {
			let node = this.$refs.tree.getNode(id),
				parent = node.data,
				orgNames = [];
			while (parent) {
				if (parent.type === 0) {
					orgNames.push(parent.text);
				}
				parent = parent.pData;
			}
			orgNames.reverse();
			return orgNames.join("");
		}
	}
};
</script>
<style lang="scss" scoped="left_tree">
.checkbox {
	cursor: pointer;
}
.left_tree {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	height: 100%;

	.vehicle_tree {
		flex: 1;
		overflow-y: auto;
		// max-height: 100%;
		margin-top: 10px;
		overflow-x: hidden;
	}

	.tree_node_span {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
		font-weight: 600;

		.content_span {
			vertical-align: middle;
			color: #333333;

			&.online {
				color: #36ab60;
			}

			img {
				vertical-align: middle;
				margin-right: 2px;
				width: 16px;
			}
		}
	}
}
</style>
