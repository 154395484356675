export default {
	data() {
		return {
			carStatus: { 1: "启动", 2: "熄火", 3: "其他", FE: "异常", FF: "无效" }, // 正常状态
			chargeStatus: { 1: "充电", 2: "行驶充电", 3: "未充电", 4: "充电完成", FE: "异常", FF: "无效" }, // 充电状态
			runModel: { 1: "纯电", 2: "混动", 3: "燃油", FE: "异常", FF: "无效" }, // 运行模式
			posStatus: { 0: "有效", 1: "无效" }, // 定位状态
			eMotoStatus: { 1: "耗电", 2: "发电", 3: "关闭状态", 4: "准备状态", FE: "异常", FF: "无效" }, // 驱动电机运行状态
			dcdcStatus: { 1: "工作", 2: "断开", FE: "异常", FF: "无效" } // DC-DC状态
		};
	}
};
